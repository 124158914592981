import { MockStorageService } from '../mock/MockStorageService';
import { MetadataService } from './MetadataService';
import { migrateMetadata } from '../mock/MockMigrate';

const metadataServiceKey = 'metadataService';

export class MockMetadataService implements MetadataService {
  private metadataMap: Play.NFTMetadataMap;
  constructor(
    private mockService: MockStorageService
  ) {
    try {
      this.metadataMap = mockService.getJson(metadataServiceKey) ?? {};
    } catch (e) {
      this.metadataMap = {};
      mockService.putJson(metadataServiceKey, this.metadataMap);
    }
    this.migrate();
  }

  private migrate() {
    Object.values(this.metadataMap).forEach(migrateMetadata);
    this.mockService.putJson(metadataServiceKey, this.metadataMap);
  }

  async putMetadatas(metadatas: Play.NFTMetadata[]): Promise<void> {
    metadatas.forEach(metadata => {
      this.metadataMap[metadata.id] = metadata;
    });
    this.mockService.putJson(metadataServiceKey, this.metadataMap);
  }

  async loadNFTMetadata(nftIds: number[], address: string): Promise<Play.NFTMetadataMap> {
    const map = {};
    nftIds.forEach(id => map[id] = {...this.metadataMap[id]});
    return map;
  }
  async loadSingleMetadata(nftId: number, address: string): Promise<Play.NFTMetadata> {
    return {...this.metadataMap[nftId]};
  }
}
