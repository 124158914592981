import React, { useMemo } from 'react';
import './MockLoginScreen.scss';
import { MockModal, ModalCloseCallback } from './MockModal';
import { MockLoginUser, mockUsers } from './MockUtils';

export enum MockLoginActionType {
  Add, Select, Close
}

export type MockLoginCallback = (actionType: MockLoginActionType, data?: any) => void;

export function MockLoginScreen({ callback }: { callback: MockLoginCallback }): JSX.Element {

  const users: MockLoginUser[] = useMemo(() => [...mockUsers], []);

  function chooseUser(e) {
    const address = e.currentTarget.dataset.address;
    const user = users.find(user => user.address === address);
    callback(MockLoginActionType.Select, user);
  }

  return !users ? (
    <div className="card-body">
      Loading...
    </div>
  ) : (
    <ul className="list-group list-group-flush">
      { users.map(user => (
        <li key={user.address} className="list-group-item" onClick={chooseUser} data-address={user.address}>
          <p>{user.name}</p>
          <small className="text-muted">{user.address}</small>
        </li>
      ))}
    </ul>
  );
}

export function createLoginScreen(callback: MockLoginCallback, closeCallback: ModalCloseCallback): MockModal {
  return new MockModal('Login', MockLoginScreen, {}, 'mock-login-screen', callback, closeCallback);
}
