import React, { SyntheticEvent } from 'react';
import debug from 'debug';
import { waitForMs } from '../../common/WaitUtils';
import { momentVideoDelayMs, momentVideoFirstDelayMs } from '../../consts';

import transitionCommon from '../../assets/transitions/Common_Transition.webm';
import transitionRare from '../../assets/transitions/Rare_Transition.webm';
import transitionEpic from '../../assets/transitions/Epic_Transition.webm';

import './MomentVideo.scss';

const log = debug('app:components:moment-video:MomentVideo');

function parseVideoUri(url: string) {
  if (url.startsWith('ipfs')) {
    const uri = url.split('://')[1];
    return `https://ipfs.io/ipfs/${uri}`;
  }

  return url;
}

export type VideoProps = Pick<Play.NFTMetadata, 'id' | 'uri' | 'rarity' | 'card'> & {
  paused?: boolean,
  className?: string,
  loop?: boolean,
  onEnd?: (id: number) => void
};

type VideoState = {
  videoReady: boolean,
  transitionReady: boolean,
  showLoading: boolean,
  showVid: boolean,
  showTransition: boolean,
  showCard: boolean
};;

export class MomentVideo extends React.Component<VideoProps, VideoState> {

  state = {
    videoReady: false,
    transitionReady: false,
    showLoading: true,
    showVid: false,
    showTransition: false,
    showCard: false
  };

  initted = false;

  videoEl: HTMLVideoElement;
  transitionEl: HTMLVideoElement;

  videoSrc: string;
  transitionSrc: string;

  transitionHalfMs = -1;
  videoEnded = false;

  constructor(props) {
    super(props);

    this.onPreloadDone = this.onPreloadDone.bind(this);
    this.setVideoEl = this.setVideoEl.bind(this);
    this.setTransitionEl = this.setTransitionEl.bind(this);
    this.onVideoEnded = this.onVideoEnded.bind(this);
    this.onTransitionEnded = this.onTransitionEnded.bind(this);

    this.updateVideoSrc();
    this.updateTransitionSrc();
  }

  componentDidMount(): void {
      
  }

  shouldComponentUpdate(nextProps: Readonly<VideoProps>, nextState: Readonly<VideoState>, nextContext: any): boolean {
    this.updateVideoSrc();
    this.updateTransitionSrc();

    return true;
  }

  componentDidUpdate(
    prevProps: Readonly<VideoProps>,
    prevState: Readonly<VideoState>,
    snapshot?: any
  ): void {
    if (!prevState.showTransition && this.state.showTransition) {
      this.playTransition();
    }
    if (!prevState.showVid && this.state.showVid) {
      this.videoEl.currentTime = 0;
      if (!this.props.paused) {
        this.videoEl.play();
      }
    }
    this.maybeInit();
    if (prevProps.paused !== this.props.paused) {
      this.updatePause();
    }
  }

  updateVideoSrc() {
    this.videoSrc = this.props.uri ? parseVideoUri(this.props.uri) : null;
  }

  updateTransitionSrc() {
    const rarity = this.props.rarity.toLowerCase();

    switch (rarity) {
    case 'epic': this.transitionSrc = transitionEpic; break;
    case 'rare': this.transitionSrc = transitionRare; break;
    case 'common': // intentional fallthrough
    default:
      this.transitionSrc = transitionCommon;
    }
  }

  setVideoEl(videoEl: HTMLVideoElement) {
    if (!videoEl) { return; }

    this.videoEl = videoEl;
  }

  setTransitionEl(transitionEl: HTMLVideoElement) {
    if (!transitionEl) { return; }

    this.transitionEl = transitionEl;
  }

  async maybeInit() {
    if (this.initted) { return; }
    if (!this.state.videoReady) { return; }
    if (!this.state.transitionReady) { return; }

    this.initted = true;
    this.setState({ showLoading: false, showCard: true });
    await waitForMs(momentVideoFirstDelayMs);
    this.startVideo();
  }

  async updatePause() {
    if (!this.videoEl) { return; }
    if (!this.transitionEl) { return; }

    if (this.props.paused) {
      if (this.state.showVid) {
        this.videoEl.pause();
      }
    } else {
      if (this.state.showVid) {
        this.videoEl.play();
      } else {
        await waitForMs(momentVideoFirstDelayMs);
        this.startVideo();
      }
    }
  }

  startVideo() {
    this.videoEnded = false;
    if (!this.props.paused) {
      this.setState({ showLoading: false, showTransition: true });
    }
  }

  async onVideoEnded() {
    this.videoEnded = true;
    this.setState({ showTransition: true });
    await waitForMs(this.transitionHalfMs);
    this.setState({ showVid: false, showCard: true });
    if (this.props.loop) {
      await waitForMs(momentVideoDelayMs);
      this.startVideo();
    } else {
      await waitForMs(this.transitionHalfMs);
      this.props.onEnd?.(this.props.id);
    }
  }

  async playTransition() {

    this.transitionEl.currentTime = 0;
    await this.transitionEl.play();
    await waitForMs(this.transitionHalfMs);

    if (!this.videoEnded) {
      this.setState({ showCard: false, showVid: true })
    }
  }

  isVideoPlaying() {
    if (!this.videoEl) { return false; }

    return !this.videoEl.paused && !this.videoEl.ended
      && this.videoEl.currentTime > 0 && this.videoEl.readyState > 2;
  }

  onPreloadDone(e: SyntheticEvent<HTMLVideoElement>) {
    if (e.target === this.videoEl) {
      this.setState({ videoReady: true });
    } else if (e.target === this.transitionEl) {
      this.transitionHalfMs = this.transitionEl.duration * 1000 / 2;
      this.setState({ transitionReady: true });
    }
  }

  onTransitionEnded() {
    this.setState({ showTransition: false });
  }

  render() {
    const loadingClassName = this.state.showLoading ? 'show' : '';
    const vidClassName = this.state.showVid ? 'show' : '';
    const transitionClassName = this.state.showTransition ? 'show' : '';
    const cardClassName = this.state.showCard ? 'show' : '';
    const thumbnail = this.props.card;
    return (
      <div className={`moment-video ${this.props.className ?? ''}`}>
        <div className="vid-gen-con d-flex justify-content-center align-items-center">
          <div className={`vid-gen-item d-flex justify-content-center align-items-center ${loadingClassName}`}>
            <div className="spinner-border text-light" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          </div>
        </div>
        <div className="vid-card-con vid-gen-con d-flex justify-content-center align-items-center">
          <div className={`vid-card-wrapper vid-gen-item d-flex justify-content-center align-items-center ${cardClassName}`}>
            <img className="vid-card" src={this.props.card} />
          </div>
        </div>
        <div className="vid-con vid-gen-con d-flex justify-content-center align-items-center">
          <video className={`vid vid-gen-item ${vidClassName}`} poster={thumbnail} muted ref={ this.setVideoEl }
            preload="auto" onCanPlayThrough={this.onPreloadDone} onEnded={this.onVideoEnded}
          >
            <source src={this.videoSrc} type="video/mp4" />
          </video>
        </div>
        <div className="vid-transition-con vid-gen-con d-flex justify-content-center align-items-center">
          <video className={`vid-transition vid-gen-item ${transitionClassName}`} muted ref={ this.setTransitionEl }
            preload="auto" onCanPlayThrough={this.onPreloadDone} onEnded={this.onTransitionEnded}
          >
            <source src={this.transitionSrc} type="video/webm" />
          </video>
        </div>
      </div>
    );
  }
}
