
import React from 'react';
import debug from 'debug';

import { NavigateFunction, useNavigate, useParams } from 'react-router';
import { PackSale, PacksService } from '../../services/packs/PacksService';
import { getPacksService } from '../../services/ServiceFactory';
import { Page } from '../Page';
import { Alert } from '../../components/alerts/Alert';
import {PackSaleDetails} from './PackSaleDetails';

import './PackSinglePage.scss';
import { SaleState } from '../../common/SaleState';
import { useUserState } from '../../services/user/UserContext';
import { UserAccount } from '../../common/UserAccount';
import { TransferModal } from '../../components/transfer-modal/TransferModal';

const log = debug('app:pages:packs:PackSinglePage');

type PageProps = {
  saleIdStr: string,
  packsService: PacksService,
  userState: UserAccount,
  navigate: NavigateFunction
};

type PageState = {
  saleId: number,
  packSale: PackSale,
  saleState: SaleState,
  rarityCaption: JSX.Element[],
  loading: boolean,
  depositModal: boolean,
  error: string
};

function SaleStateStatus(
  { saleState, openDepositModal }: {
    saleState: SaleState,
    openDepositModal: (e?: React.MouseEvent<any>) => void
  }
): JSX.Element {
  switch (saleState) {
  case SaleState.LogInRequired:
    return <div><small>Wallet needs to be connected to buy.</small></div>;
  case SaleState.LinkRequired:
    return <div><small>Wallet needs to be linked to buy.</small></div>;
  case SaleState.OutOfStock:
    return <div><small>Out of Stock.</small></div>;
  case SaleState.Unavailable:
    return <div><small>This pack is unavailable.</small></div>;
  case SaleState.Unaffordable:
    return <div><small>Unaffordable. <a href="#" onClick={openDepositModal}>Deposit ETH</a> to be able to buy.</small></div>;
  case SaleState.Available:
    return null;
  default:
    return <div><small>Unexpected Sale State.</small></div>;
  }
}

class InternalSinglePage extends React.Component<PageProps, PageState> {
  constructor(props: PageProps) {
    super(props);

    this.state = {
      saleId: -1,
      packSale: null,
      saleState: undefined,
      rarityCaption: null,
      loading: true,
      depositModal: false,
      error: null
    };

    this.buy = this.buy.bind(this);
    this.openDepositModal = this.openDepositModal.bind(this);
    this.closeDepositModal = this.closeDepositModal.bind(this);
  }
  componentDidMount() {
    this.init();
  }
  componentDidUpdate(prevProps: Readonly<PageProps>, prevState: Readonly<PageState>) {
    if (prevProps.userState.balance !== this.props.userState.balance ||
      prevState.saleId !== this.state.saleId
    ) {
      this.updateSaleState();
    }
  }
  async init() {
    let saleId: number;
    try {
      saleId = parseFloat(this.props.saleIdStr);
      this.setState({ saleId, error: null });
    } catch (e) {
      this.setState({ loading: false, error: 'Invalid sale ID' });
      return;
    }

    let packSale: PackSale;
    try {
      packSale = await this.props.packsService.getSingleSale(saleId);
      this.setState({ packSale, error: null })
    } catch (e) {
      log('Error loading pack:', e);
      this.setState({ loading: false, error: 'Error loading pack '});
      return;
    }

    if (!packSale) { return; }

    this.setState({ loading: false, error: null});
  }

  async updateSaleState() {
    if (this.state.saleId === -1) { return; }
    const saleState = await this.props.packsService.canBuyPack(this.state.saleId);
    this.setState({ saleState });
  }

  async buy() {
    if (!this.state.saleId) {
      return;
    }
    try {
      const packId = await this.props.packsService.buyPack(this.state.saleId);
      this.props.navigate(`/profile/me/packs/${packId}/reveal`);
    } catch (e) {
      this.setState({ error: e.message });
    }
  }

  openDepositModal(e?: React.MouseEvent<any>) {
    if (e) {
      e.preventDefault();
    }

    this.setState({ depositModal: true });
  }

  closeDepositModal() {
    this.setState({ depositModal: false });
  }

  render() {
    const pageTitle = this.state.packSale?.name ?? 'Pack';
    const sale = this.state.packSale;

    const rarityClassName = sale?.rarity.toLocaleLowerCase();

    return (
      <Page title={pageTitle} className="pack-page container py-2">
        {this.state.error && <Alert.Danger>{this.state.error}</Alert.Danger>}
        {this.state.loading && <Alert.Info>Loading...</Alert.Info>}
        {sale ? (
          <div className="d-flex flex-wrap">
            <div className={`pack-sale-graphics ${rarityClassName}`}>
              <div className={`pack-rarity-text ${rarityClassName}`} />
              <div className="pack-name d-flex flex-column-reverse"><p>{sale.name}</p></div>
              <img className="pack-icon" src={sale.imgSrc} />
            </div>
            <div className={`pack-sale-cta ${rarityClassName}`}>
              <p><span>Get your</span> first pack instantly</p>
              <button className="btn btn-success"
                onClick={this.buy}
                disabled={this.state.saleState !== SaleState.Available}
              >Get Your First Pack</button>
              <SaleStateStatus saleState={this.state.saleState} openDepositModal={this.openDepositModal} />
            </div>
            <PackSaleDetails rarity={rarityClassName} />
          </div>
        ) : (
          <Alert.Warning>No metadata</Alert.Warning>
        )}
        <TransferModal
          show={this.state.depositModal}
          minimum={this.state.packSale?.price}
          deposit
          onDismiss={this.closeDepositModal}
        />
      </Page>
    )
  }
}

export function PackSinglePage() {
  const { saleId } = useParams();
  const packsService = getPacksService();
  const userState = useUserState();
  const navigate = useNavigate();

  return (
    <InternalSinglePage
      saleIdStr={saleId}
      packsService={packsService}
      userState={userState}
      navigate={navigate}
    />
  )
}
