import React, { useState } from 'react';
import debug from 'debug';

import { Page } from '../Page';
import { UserPermission } from '../../common/UserPermission';
import { getAdminService } from '../../services/ServiceFactory';
import { ProtoMetadata } from '../../services/admin/AdminService';
import { Alert } from '../../components/alerts/Alert';
import { Rarity } from '../../services/packs/PacksService';

import './AdminPage.scss';
import packCommon from '../../assets/Common.png';
import packRare from '../../assets/Rare.png';
import packEpic from '../../assets/Epic.png';

import cardCommon from '../../assets/sample-moments/mockCommon.png';
import cardRare from '../../assets/sample-moments/mockRare.png';
import cardEpic from '../../assets/sample-moments/mockEpic.png';

import video1 from '../../assets/sample-moments/mc_rae_chat.mp4';
import video2 from '../../assets/sample-moments/mc_rae_phone.mp4';
import video3 from '../../assets/sample-moments/mc_realization.mp4';
import video4 from '../../assets/sample-moments/griffin_interruption.mp4';

const log = debug('app:pages:admin:AdminPage');

const requiredPermissions = [UserPermission.Admin];
const consonantPool = 'bcdfghjklmnpqrstvwxyz';
const uriPool = [
  video1,
  video2,
  video3,
  video4
];
const rarityPool = [
  Rarity.Common,
  Rarity.Rare,
  Rarity.Epic
];
const rarityPackImgMap = {
  [Rarity.Common]: packCommon,
  [Rarity.Rare]: packRare,
  [Rarity.Epic]: packEpic
};

const rarityCardImgMap = {
  [Rarity.Common]: cardCommon,
  [Rarity.Rare]: cardRare,
  [Rarity.Epic]: cardEpic
};

const addressRegex = /^0x[0-9a-f]+$/i;
const playTokenRegex = /^\d+\.\d+$/;

let rarityIndex = 0;

function randomPick<T>(arr: T[]): T {
  return arr[Math.floor(Math.random() * arr.length)];
}

function randomConsonant() {
  return consonantPool.charAt(Math.floor(Math.random() * consonantPool.length));
}

function generateName() {
  let name = '';
  const syllables = Math.floor(2 + Math.random() * 4);
  for (let i = 0; i < syllables; i++) {
    const c = randomConsonant();
    name += (i === 0 ? c.toUpperCase() : c) + 'a';
  }

  return name;
}

function randomRarity(): string {
  return randomPick(rarityPool);
}

function randomUri(): string {
  return randomPick(uriPool);
}

function nextRarity(): Rarity {
  const rarity = rarityPool[rarityIndex++];
  if (rarityIndex >= rarityPool.length) {
    rarityIndex = 0;
  }
  return rarity;
}

export function AdminPage() {
  const adminService = getAdminService();
  const [details, setDetails] = useState(null as Play.ServiceAccountDetails);
  const [loading, setLoading] = useState(null as string);
  const [status, setStatus] = useState('Nothing for now.');
  const [mockJson, setMockJson] = useState(null as string);
  const [error, setError] = useState(null as string);
  const [playReceiverAddress, setPlayReceiverAddress] = useState('');
  const [playTokenAmount, setPlayTokenAmount] = useState('');
  const [chainBalanceAddress, setChainBalanceAddress] = useState('');
  const [chainBalanceAmount, setChainBalanceAmount] = useState('');

  async function linkService() {
    setError(null);
    setLoading('Linking service account...');
    try {
      await adminService.linkService();
      setLoading(null);
      loadServiceSetup();
    } catch (e) {
      log('error linking service account', e);
      setError(e.toString());
      setLoading(null);
    }
  }

  async function loadServiceSetup() {
    setLoading('Loading details...');
    const deets = await adminService.loadServiceSetup();
    setDetails(deets);
    setLoading(null);
  }

  async function mintOneNFT(): Promise<number> {
    setError(null);
    const name = generateName();
    const rarity = randomRarity();
    const uri = randomUri();
    try {
      setStatus('Minting 1 NFT...');
      const nftId = await adminService.mintNFT(
        `${rarity} ${name}`,
        rarity,
        uri
      );
      setStatus(`Minted 1 NFT (id: ${nftId}).`);
      loadServiceSetup();
      return nftId;
    } catch (e) {
      log('error minting', e);
      setError(e.toString());
      setLoading(null);
      setStatus('Nothing for now.');
      return undefined;
    }
  }

  async function mintMultipleNFTs(): Promise<number[]> {
    const amount = 5;
    const metadatas = []
    for (let i = 0; i < amount; i++) {
      const name = generateName();
      const rarity = randomRarity();
      const uri = randomUri();
      const fullName = `${rarity} ${name}`;
      metadatas.push({ name: fullName, rarity, uri });
    }
    if (metadatas.length === 0) { return }

    try {
      setStatus('Minting multiple NFTs...');
      const nftIds = await adminService.mintMultiNFT(metadatas);
      setStatus(`Minting multiple NFTs done (IDs: ${nftIds.join(', ')}).`);
      loadServiceSetup();
      return nftIds;
    } catch (e) {
      log('error minting multiple nfts', e);
      setError(e.toString());
      setLoading(null);
      setStatus('Nothing for now.');
      return undefined;
    }
  }

  async function mintPlayTokens() {
    if (!addressRegex.test(playReceiverAddress)) {
      setError('Invalid receiver address');
      return;
    }
    if (!playTokenRegex.test(playTokenAmount)) {
      setError('Invalid play token amount');
      return;
    }

    setError(null);

    try {
      setStatus('Minting play tokens...');
      await adminService.mintPlayTokens(playReceiverAddress, playTokenAmount);
      setStatus('Minted play tokens.');
      loadServiceSetup();
    } catch (e) {
      log('error minting play tokens', e);
      setError(e.toString());
      setStatus('Nothing for now.');
    }
  }

  async function addToChainBalance() {
    if (!addressRegex.test(chainBalanceAddress)) {
      setError('Invalid receiver address');
      return;
    }
    if (!playTokenRegex.test(chainBalanceAmount)) {
      setError('Invalid chain balance amount');
      return;
    }

    setError(null);

    try {
      setStatus('Adding to chain balance...');
      await adminService.addToChainBalance(chainBalanceAddress, chainBalanceAmount);
      setStatus('Added to chain balance.');
    } catch (e) {
      log('error adding to chain balance:', e);
      setError(e.toString());
      setStatus('Nothing for now.');
    }
  }

  async function createPackSale() {
    const numPacks = 2;
    const numNftsPerPack = 3;
    const metadataSets: ProtoMetadata[][] = [];
    for (let i = 0; i < numPacks; i++) {
      const metadatas: ProtoMetadata[] = [];
      for (let j = 0; j < numNftsPerPack; j++) {
        const name = generateName();
        const rarity = randomRarity();
        const uri = randomUri();
        const fullName = `${rarity} ${name}`;
        const card = rarityCardImgMap[rarity];
        const thumbnail = card;
        metadatas.push({ name: fullName, rarity, uri, card, thumbnail });
      }
      metadataSets.push(metadatas);
    }
    const packRarity = nextRarity();
    const packSaleName = `${packRarity} ${generateName()} Pack`;
    const packImgSrc = rarityPackImgMap[packRarity];
    const packPrice = '10.000000';
    try {
      setStatus('Creating pack sale...');
      const packSale = await adminService.createPackSale(
        packSaleName, packImgSrc, packRarity, true, 0, packPrice, metadataSets
      );
      setStatus(`Created pack sale "${packSale.name}""`);
      loadServiceSetup();
    } catch (e) {
      log('error creating pack sale', e);
      setError(e.toString());
      setStatus('Nothing for now.');
    }
  }

  function onPlayAddressChange(e) {
    setPlayReceiverAddress(e.target.value);
  }

  function onPlayTokenChange(e) {
    setPlayTokenAmount(e.target.value);
  }

  function onChainBalanceAddressChange(e) {
    setChainBalanceAddress(e.target.value);
  }

  function onChainBalanceAmountChange(e) {
    setChainBalanceAmount(e.target.value);
  }

  function clearData() {
    adminService.clearMockData?.();
    setStatus('Mock data cleared. Refresh required.');
  }

  function resetMockData() {
    adminService.allowMockData?.();
    setStatus('Resetting mock data. Refresh required.');
  }

  function exportMockData() {
    const dataJson = adminService.exportMockData?.();
    setMockJson(dataJson);
  }

  async function copyMockData() {
    await window.navigator.clipboard.writeText(mockJson);
    setStatus('Copied mock data.');
  }

  const nfts = !details ? 'Not loaded' :
    !details.nfts ? 'n/a' :
      details.nfts.length === 0 ? '[empty]' :
        details.nfts.join(', ');
  
  const sale = !details ? 'Not loaded' :
    !details.sale ? 'n/a' :
      details.sale.length === 0 ? '[empty]' :
        details.sale.join(', ');

  return (
    <Page title="Admin" className="admin container py-2" restricted={requiredPermissions}>
      {error && (
        <Alert.Danger dismissible onDismiss={() => setError(null)}>
          {error}
        </Alert.Danger>
      )}
      <div>
        <button className="btn btn-primary" onClick={linkService}>
          Link Service
        </button>
        <button className="btn btn-secondary ms-2" onClick={loadServiceSetup}>
          Load Details
        </button>
        <button className="btn btn-success ms-2" onClick={mintOneNFT}>
          Mint Single NFT
        </button>
        <button className="btn btn-success ms-2" onClick={mintMultipleNFTs}>
          Mint Five NFTs
        </button>
        <button className="btn btn-success ms-2" onClick={createPackSale}>
          Create Pack Sale
        </button>
        <button className="btn btn-danger ms-2" onClick={clearData} disabled={!adminService.clearMockData}>
          Clear Mock Data
        </button>
        <button className="btn btn-danger ms-2" onClick={resetMockData} disabled={!adminService.allowMockData}>
          Reset Mock Data
        </button>
        <button className="btn btn-danger ms-2" onClick={exportMockData} disabled={!adminService.exportMockData}>
          Export Mock Data
        </button>
      </div>
      <div className="row">
        <div className="col-6">
          <div className="card m-2 bg-dark">
            <div className="card-header">
              Mint Play Tokens
            </div>
            <div className="card-body">
              <div className="mb-2">
                <label htmlFor="admin-play-receiver-address" className="form-label">Play Token Receiver Address</label>
                <input
                  type="text"
                  id="admin-play-receiver-address"
                  className="form-control"
                  onChange={onPlayAddressChange}
                  value={playReceiverAddress}
                />
              </div>
              <div className="mb-2">
                <label htmlFor="admin-play-token-amount" className="form-label">Play Token Amount</label>
                <input
                  type="text"
                  id="admin-play-token-amount"
                  className="form-control"
                  onChange={onPlayTokenChange}
                  value={playTokenAmount}
                />
              </div>
              <div>
                <button className="btn btn-success" onClick={mintPlayTokens}>Mint Play Tokens</button>
              </div>
            </div>
          </div>
        </div>

        <div className="col-6">
          <div className="card m-2 bg-dark">
            <div className="card-header">
              Add to Chain Balance
            </div>
            <div className="card-body">
              <div className="mb-2">
                <label htmlFor="admin-chain-receiver-address" className="form-label">Chain Receiver Address</label>
                <input
                  type="text"
                  id="admin-chain-receiver-address"
                  className="form-control"
                  onChange={onChainBalanceAddressChange}
                  value={chainBalanceAddress}
                />
              </div>
              <div className="mb-2">
                <label htmlFor="admin-chain-balance-amount" className="form-label">Chain Balance Amount</label>
                <input
                  type="text"
                  id="admin-chain-balance-amount"
                  className="form-control"
                  onChange={onChainBalanceAmountChange}
                  value={chainBalanceAmount}
                />
              </div>
              <div>
                <button className="btn btn-success" onClick={addToChainBalance} disabled={!adminService.addToChainBalance}>Add to Chain Balance</button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <p>{status}</p>
      </div>
      <div>
        {loading ? (
          <p>{loading}</p>
        ) : details ? (
          <ul>
            <li>Balance: {details.balance ?? 'n/a'}</li>
            <li>NFTs: {nfts}</li>
            <li>Sale: {sale}</li>
            <li>Sale Initted: {details.saleInitted ? 'true' : 'false'}</li>
          </ul>
        ) : (
          <p>Not loaded</p>
        )}
      </div>
      {mockJson && (
        <div>
          <textarea className="form-control" rows={10} readOnly onClick={copyMockData}>{mockJson}</textarea>
        </div>
      )}
    </Page>
  );
}
