import React, { SyntheticEvent, useRef } from 'react';
import { Link } from 'react-router-dom';
import debug from 'debug';
import { ModalCloseButton, ModalPortal } from '../modalportal/ModalPortal';
import { MomentVideo } from '../moment-video/MomentVideo';

import mockCommon from '../../assets/sample-moments/mockCommon.png';
import mockRare from '../../assets/sample-moments/mockRare.png';
import mockEpic from '../../assets/sample-moments/mockEpic.png';
import iconExpand from '../../assets/svg/moment-expand.svg';
import iconBack from '../../assets/svg/moment-back.svg';

import './Moment.scss';

const log = debug('app:components:moment:Moment');

const stopPropagation = (e: {stopPropagation: () => void}) => e.stopPropagation();

// eslint-disable-next-line arrow-body-style
const SingleMomentExpanded = (props: Pick< Play.NFTMetadata, 'id' | 'uri' | 'rarity' | 'card' | 'thumbnail'> & {onClose: () => void }) => {
  return <ModalPortal>
    <div className="single-moment-expanded" onClick={ props.onClose }>
      <ModalCloseButton />
      <div className="vid-wrapper" onClick={stopPropagation}>
        <MomentVideo id={props.id} uri={props.uri} rarity={props.rarity} card={props.card} paused={false} loop />
      </div>
    </div>
  </ModalPortal>
}

function MomentVisuals(props: Pick< Play.NFTMetadata, 'id' | 'uri' | 'rarity' | 'card' | 'thumbnail'> & { paused: boolean}) {
  const videoSrc = props.uri ?? null;
  const rarity = props.rarity.toLowerCase();
  const card = props.card ?? rarity === 'common' ? mockCommon : rarity === 'rare' ? mockRare : mockEpic;

  return (
    <div className="moment-visuals">
      {videoSrc ? (
        <MomentVideo id={props.id} uri={props.uri} rarity={props.rarity} card={props.card} paused={props.paused} loop />
      ) : (
        <img src={card} />
      )}
    </div>
  );
}

const tags: { key: string, value: string }[] = [
  { key: 'Season', value: 'Season One' },
  { key: 'Episode', value: 'Episode 1' },
  { key: 'Collection', value: 'Mastery: Flirting' },
  { key: 'Location', value: 'Mastery: Flirting' },
  { key: 'Tone Tags', value: 'Funny, Cringe' },
  { key: 'Game Tags', value: 'Choice, Dialogue' },
]

type MomentProps = {
  metadata: Play.NFTMetadata,
  backText: string,
  backURL: string,
  children?: React.ReactNode
};

export const Moment = ({metadata, backText, backURL, children}: MomentProps) => {
  const [expand, setExpand] = React.useState(false);
  return <div className="d-flex flex-column single-moment card">
    <div className="d-flex justify-content-between align-items-center">
      <Link className="single-moment-back d-flex flex-row" to={ backURL }>
        <img src={iconBack} />
        <div className="single-moment-back-text"> { backText } </div>
      </Link>
      <div className="d-flex justify-content-end m-4">
        <button className="btn btn-secondary">
            Previous
        </button>
        <button className="btn btn-secondary">
            Next
        </button>
      </div>
    </div>

    <div className="single-moment-card-main-row d-flex flex-row justify-content-between">

      <div className="d-flex flex-row align-items-end">
        <MomentVisuals id={metadata.id} uri={metadata.uri} rarity={metadata.rarity} card={metadata.card} thumbnail={metadata.thumbnail} paused={ expand }/>
        { expand && <SingleMomentExpanded id={metadata.id} uri={metadata.uri} rarity={metadata.rarity} card={metadata.card} thumbnail={metadata.thumbnail} onClose={() => { setExpand(false) } }/> }
        <div>
          <img className="single-moment-expand" src={iconExpand} onClick={() => setExpand(true)}/>
        </div>
      </div>

      <div>

        <div className="moment-text d-flex flex-column">
          <div>
            <div className="d-flex justify-content-between">
              <h2>Moment Name</h2>
              <span className="single-moment-text-available">Available</span>
            </div>
            <h1>{metadata.name}</h1>
          </div>

          <div>
            <h4>Moment Type</h4>
            <h3>{metadata.rarity} &nbsp;# 3 / 49</h3>
          </div>

          { children }

          <div className="moment-tags d-flex flex-wrap justify-content-between">
            {tags.map(tag => (
              <div className="moment-tag" key={tag.key} >
                <h5>{tag.key}</h5>
                <p>{tag.value}</p>
              </div>
            ))}
          </div>

        </div>
      </div>
    </div>
  </div>
}
