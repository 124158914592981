import { useEffect } from 'react';
import { ModalCloseButton, ModalPortal } from '../../components/modalportal/ModalPortal';
import { NFTCard, NFTCardSize } from '../../components/nft-card/NFTCard';

import warnIcon from '../../assets/svg/warn.svg';
import sellIcon from '../../assets/svg/marketplace.svg';
import './SellDialog.scss'
import { Link } from 'react-router-dom';

const stopPropagation = (e: {stopPropagation: () => void}) => e.stopPropagation();

export const SellDialog = ({onClose, metadata, price, onPriceChange, addToMarket, error, fee, addingToMarket}:
{metadata: Play.NFTMetadata, price: string, onPriceChange: (e: any) => void, onClose: () => void,
  addToMarket: () => void, error: string, fee: string, addingToMarket: boolean}) => {
  const feesNum = error ? NaN : Number.parseFloat(price) * Number.parseFloat(fee) / 100;
  const feesText = isNaN(feesNum) ? '-' : feesNum.toFixed(2);
  const payoutNum = error ? NaN : Number.parseFloat(price) * (100 - Number.parseFloat(fee)) / 100;
  const payoutText = isNaN(payoutNum) ? '-' : payoutNum.toFixed(2);
  error = !!price && error;
  const canAddToMarket = !addingToMarket && !error && !!price;
  return <ModalPortal>
    <div className="sell-dialog-container" onClick={ onClose }>
      <ModalCloseButton />
      <div className="sell-dialog" onClick={ stopPropagation }>
        <div className="sell-dialog-top-bg"/>
        <div className="sell-dialog-bottom-bg"/>
        <div className="sell-dialog-content">
          <div className="sell-dialog-title-1">SELL</div>
          <div className="sell-dialog-title-2">YOUR MOMENT</div>
          <div className="sell-dialog-nft-card">
            <NFTCard size={NFTCardSize.Small} nftMetadata={metadata} />
          </div>
          <div className="sell-dialog-input-container">
            <div className="sell-dialog-input-error">{ error }</div>
            <input className="sell-dialog-input" value={ price } onChange={ onPriceChange }/>
          </div>
          <div className="sell-dialog-price-info">
            <div className="sell-dialog-price-helper">
              <img src={warnIcon} />
              <Link to="#">Price helper</Link>
            </div>
          </div>
          <div className="sell-dialog-buttons">
            <button onClick={ onClose } className="sell-dialog-button-cancel" >CANCEL</button>
            <button onClick={ canAddToMarket ? addToMarket : undefined }
              className={ `sell-dialog-button-sell${canAddToMarket ? '' : ' disabled'}` }>
              <img src={sellIcon} />
              { addingToMarket ? 'ADDING TO MARKET...' : 'PLACE FOR SALE' }
            </button>
          </div>
          <div className="sell-dialog-hr"/>
          <div className="sell-dialog-marketplace-fee">
            <div>Marketplace Fee({fee}%)</div>
            <div>{ feesText }</div>
          </div>
          <div className="sell-dialog-hr"/>
          <div className="sell-dialog-total-payout">
            <div>Total Payout</div>
            <div>{ payoutText }</div>
          </div>
          <div className="sell-dialog-bottom-hr" />
          <div className="sell-dialog-footer" >
            To learn more about fees,<Link to="#">click here</Link>
          </div>
        </div>
      </div>
    </div>
  </ModalPortal>
}
