import mockCommon from '../../assets/sample-moments/mockCommon-300.png';
import mockRare from '../../assets/sample-moments/mockRare-300.png';
import mockEpic from '../../assets/sample-moments/mockEpic-300.png';

import video1 from '../../assets/sample-moments/mc_rae_chat.mp4';
import video2 from '../../assets/sample-moments/mc_rae_phone.mp4';
import video3 from '../../assets/sample-moments/mc_realization.mp4';
import video4 from '../../assets/sample-moments/griffin_interruption.mp4';

export function migrateMetadata(metadata: Play.NFTMetadata) {
  switch (metadata.rarity) {
  case 'Common':
    metadata.thumbnail = metadata.card = mockCommon;
    break;
  case 'Rare':
    metadata.thumbnail = metadata.card = mockRare;
    break;
  case 'Epic':
    metadata.thumbnail = metadata.card = mockEpic;
    break;
  }

  switch (metadata.uri) {
  case 'ipfs://QmZieefaCMX9u4iZDZAVXhdZdu2LCC1NPxwHAsrQiH1urs':
    metadata.uri = video1;
    break;
  case 'ipfs://QmaoKfwyH7jBA1y14htjzMPdHuo4ieFMSNjcC6vQSPe522':
    metadata.uri = video2;
    break;
  case 'ipfs://QmZA4K9ByadS5AWRhNe5gQWSGYv5z1d7nWqfb2oFeUwG8s':
    metadata.uri = video3;
    break;
  case 'ipfs://QmRrXusJn9RbRgYB6MXDyppP5JVHjMMNdG5CZSnBtr6gV7':
    metadata.uri = video4;
    break;
  }
}
