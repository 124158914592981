
import React, {
  useEffect,
  useMemo,
  useState,
  useRef,
  createRef,
  RefObject,
  MouseEvent
} from 'react';
import debug from 'debug';

import { Navigate, useNavigate, useParams } from 'react-router';
import { UserAccount } from '../../../common/UserAccount';
import { Alert } from '../../../components/alerts/Alert';
import { MomentVideo } from '../../../components/moment-video/MomentVideo';
import { MetadataService } from '../../../services/metadata/MetadataService';
import { Pack, PacksService, Rarity } from '../../../services/packs/PacksService';
import { getMetadataService, getPacksService } from '../../../services/ServiceFactory';
import { useUserState } from '../../../services/user/UserContext';
import { ModalCloseButton, ModalPortal } from '../../../components/modalportal/ModalPortal';
import { waitForMs } from '../../../common/WaitUtils';
import {
  cssDelay,
  revealCardBackdropFadeDelay,
  revealCardHiddenDelay,
  revealCardMoveDelay,
  revealCardMoveDuration,
  revealCardMoveToBackDelay,
  revealCardPlaybackDelay,
  revealCardRevealedDelay,
  revealCardStackedDelay,
  revealCardYOffset
} from '../../../consts';
import { Page } from '../../Page';

import cardCommon from '../../../assets/sample-moments/mockCommon-300.png';
import cardRare from '../../../assets/sample-moments/mockRare-300.png';
import cardEpic from '../../../assets/sample-moments/mockEpic-300.png';

import packOpenCommon from '../../../assets/transitions/Common_open.webm';
import packOpenRare from '../../../assets/transitions/Rare_open.webm';
import packOpenEpic from '../../../assets/transitions/Epic_open.webm';

import './RevealPackPage.scss';

const log = debug('app:pages:profile:RevealPackPage');

const enum PageLoadState {
  Idle,
  ParsingId,
  ErrorParsingId,
  LoadingPack,
  ErrorLoadingPack,
  ErrorPackAlreadyOpened,
  WaitingToOpenPack,
  OpeningPack,
  ErrorOpeningPack,
  LoadingMoments,
  ErrorLoadingMoments,
  OpeningMoments,
  Finished
}

const enum RevealState {
  Idle,
  Initting,
  Stacked,
  Hidden,
  Revealing,
  Revealed,
  Navigable
}

type PageProps = {
  packIdStr: string,
  packsService: PacksService,
  metadataService: MetadataService,
  userState: UserAccount
};

type PageState = {
  packId: number,
  pack: Pack,
  loadState: PageLoadState,
  moments: Play.NFTMetadata[],
  opened: {
    [id: number]: boolean
  },
  fadeShow: boolean,
  navigateTo: string
};

type OpenedMap = {
  [id: number]: boolean
};

type MomentCardProps = {
  moment: Play.NFTMetadata,
  opened: boolean,
  loadState: PageLoadState,
  index: number,
  numCards: number,
  revealConRef: RefObject<HTMLDivElement>,
  revealCallback: (momentId: number) => void
};

const revealShowFrontMap = {
  [RevealState.Initting]: '',
  [RevealState.Stacked]: '',
  [RevealState.Hidden]: '',
  [RevealState.Revealing]: 'show-front',
  [RevealState.Revealed]: 'show-front',
  [RevealState.Navigable]: 'show-front'
};

const revealInvisibleMap = {
  [RevealState.Idle]: 'invisible',
  [RevealState.Initting]: 'invisible',
  [RevealState.Stacked]: '',
  [RevealState.Hidden]: '',
  [RevealState.Revealing]: '',
  [RevealState.Revealed]: '',
  [RevealState.Navigable]: ''
};

const revealClickableMap = {
  [RevealState.Idle]: '',
  [RevealState.Initting]: '',
  [RevealState.Stacked]: '',
  [RevealState.Hidden]: 'clickable',
  [RevealState.Revealing]: '',
  [RevealState.Revealed]: '',
  [RevealState.Navigable]: 'clickable',
};

function AlertSpinner() {
  return (
    <div className="spinner-border spinner-border-sm text-dark" role="status"></div>
  );
}

function MomentBackdrop({ show }: { show: boolean }): JSX.Element {
  const [displayShow, setDisplayShow] = useState(false);
  const [fadeShow, setFadeShow] = useState(false);

  useEffect(() => {
    if (show) {
      setDisplayShow(true);
      const timeout = setTimeout(() => setFadeShow(true), cssDelay);
      return () => clearTimeout(timeout);
    } else {
      setFadeShow(false);
      const timeout = setTimeout(() => setDisplayShow(false), revealCardBackdropFadeDelay);
      return () => clearTimeout(timeout);
    }
  }, [show]);

  const className = useMemo(() => {
    const names = [
      'reveal-moment-backdrop',
      displayShow && 'show',
      fadeShow && 'fade-show'
    ].filter(Boolean);

    return names.join(' ');
  }, [displayShow, fadeShow]);

  return (
    <div className={className}></div>
  );
}

function MomentCard({ moment, opened, loadState, index, numCards, revealConRef, revealCallback }: MomentCardProps) {
  const [revealState, setRevealState] = useState(RevealState.Idle);
  const [moveToFront, setMoveToFront] = useState(false);
  const [videoPaused, setVideoPaused] = useState(true);
  const [videoEnded, setVideoEnded] = useState(false);
  const [videoBg, setVideoBg] = useState(false);
  const [spinEnded, setSpinEnded] = useState(false);
  const [revealEl, setRevealEl] = useState<HTMLDivElement>(null);
  const navigate = useNavigate();

  const card = useMemo(() => {
    const rarity = moment.rarity.toLowerCase();
    return moment.card ? moment.card : rarity === 'epic' ? cardEpic : rarity === 'rare' ? cardRare : cardCommon;
  }, [moment]);

  // when cards are in their positions, get the necessary deltas so it's
  // centered during stacked and revealing states.
  const centeredTransform = useMemo(() => {
    if (!revealEl) { return ''; }

    const bodyRect = revealConRef.current.getBoundingClientRect();
    const revealRect = revealEl.getBoundingClientRect();

    const bodyX = (bodyRect.left + bodyRect.right) / 2;
    const bodyY = (bodyRect.top + bodyRect.bottom) / 2;
    const fromX = (revealRect.left + revealRect.right) / 2;
    const fromY = (revealRect.top + revealRect.bottom) / 2;

    const deltaX = bodyX - fromX;
    const deltaY = bodyY - fromY + revealCardYOffset; // adjustment to go behind pack open video

    return `translate(${deltaX}px, ${deltaY}px)`;
  }, [revealEl]);

  // once the centered transform is set, the cards can go to the center
  useEffect(() => {
    if (centeredTransform === '') { return; }

    // move to center, invisible
    setRevealState(RevealState.Initting);

    // make it visible
    const stackedTimeout = setTimeout(() => setRevealState(RevealState.Stacked), revealCardStackedDelay);

    return () => {
      clearTimeout(stackedTimeout);
    };
  }, [centeredTransform]);

  // Once the pack has opened, move the cards to their original spots.
  useEffect(() => {
    if (loadState !== PageLoadState.OpeningMoments) { return; }
    if (revealState !== RevealState.Stacked) { return; }

    revealEl.style.transitionDuration = `${revealCardMoveDuration}ms`;
    revealEl.style.transitionDelay = `${(numCards - 1 - index) * revealCardMoveDelay}ms`;

    const hiddenTimeout = setTimeout(() => setRevealState(RevealState.Hidden), revealCardHiddenDelay);

    return () => {
      clearTimeout(hiddenTimeout);
    };
  }, [loadState, revealState]);

  // several things happen here, only during the revealing state.
  useEffect(() => {
    if (opened) { return; }
    if (revealState !== RevealState.Revealing) { return; }

    // play the video after spin
    if (!videoEnded && spinEnded && videoPaused) {
      const playbackTimeout = setTimeout(() => setVideoPaused(false), revealCardPlaybackDelay);

      return () => {
        clearTimeout(playbackTimeout);
      };
    }

    // after video has ended, move the card back
    if (videoEnded) {
      const revealedTimeout = setTimeout(() => setRevealState(RevealState.Revealed), revealCardRevealedDelay);
      const moveToFrontTimeout = setTimeout(() => {
        setMoveToFront(false);
        revealCallback(moment.id);
      }, revealCardRevealedDelay + revealCardMoveToBackDelay); // after revealed state
  
      return () => {
        clearTimeout(revealedTimeout);
        clearTimeout(moveToFrontTimeout);
      };
    }
  }, [videoEnded, videoPaused, spinEnded, opened]);

  useEffect(() => {
    if (loadState !== PageLoadState.Finished) { return; }

    setRevealState(RevealState.Navigable);
  }, [loadState]);

  // move to center and start spin animation
  function startReveal() {
    revealEl.style.transitionDelay = '';

    setRevealState(RevealState.Revealing);
    setMoveToFront(true);
  }

  function goToMoment() {
    navigate(`/profile/me/moments/${moment.id}`);
  }

  function momentClicked(e: MouseEvent<HTMLDivElement>) {
    e.stopPropagation();
    if (revealState === RevealState.Hidden) {
      startReveal();
    } else if (revealState === RevealState.Navigable) {
      goToMoment();
    }
  }

  // spin animation end
  function onRevealAnimEnd() {
    setSpinEnded(true);
    setVideoBg(true);
  }

  // moment video end
  function onVideoEnd(id: number) {
    setVideoBg(false);
    setVideoEnded(true);
  }

  function getTransformFromRevealState() {
    switch (revealState) {
    case RevealState.Initting:
    case RevealState.Stacked:
    case RevealState.Revealing:
      return centeredTransform;
    default:
      return '';
    }
  }

  const hasVideo = !!moment.uri;
  const transform = getTransformFromRevealState();
  const showFront = revealShowFrontMap[revealState];
  const showVideoBg = videoBg ? 'show-video-bg' : '';
  const invisible = revealInvisibleMap[revealState];
  const clickable = revealClickableMap[revealState];

  return (
    <>
      <MomentBackdrop show={revealState === RevealState.Revealing} />
      <div className={`reveal-moment ${moveToFront ? 'move-to-front' : ''} ${showFront} ${invisible} ${clickable}`}
        style={{ transform: transform }}
        onClick={momentClicked} ref={setRevealEl}
      >
        <div className="reveal-moment-flipper" onAnimationEnd={onRevealAnimEnd}>
          <div className="reveal-moment-back d-flex justify-content-center align-items-center"></div>
          <div className={`reveal-moment-front d-flex justify-content-center align-items-center ${showVideoBg}`}>
            { hasVideo ? (
              <MomentVideo id={moment.id} uri={moment.uri} 
                rarity={moment.rarity} card={moment.card}
                paused={videoPaused} onEnd={onVideoEnd}
              />
            ) : (
              <img className="reveal-moment-card" src={card} />
            )}
          </div>
        </div>
      </div>
    </>
  );
}

function RevealSection(
  { moments, loadState, revealConRef, onOpenedAll }:
  { moments: Play.NFTMetadata[], loadState: PageLoadState, revealConRef: RefObject<HTMLDivElement>, onOpenedAll: () => void }
) {
  const [opened, setOpened] = useState({} as OpenedMap);

  useEffect(() => {
    setOpened(
      Object.fromEntries(
        moments.map(moment => ([moment.id, false]))
      )
    );
  }, []);

  useEffect(() => {
    const values = Object.values(opened);
    if (values.length === 0) { return; }
    
    if (values.every(value => value)) {
      onOpenedAll();
    }
  }, [opened]);
  
  function openMoment(momentId) {
    const newOpened = {...opened};
    newOpened[momentId] = true;
    setOpened(newOpened);
  }

  return (
    <div className="reveal-section reveal-child d-flex flex-wrap justify-content-evenly">
      {moments.map((moment, index, array) => (
        <MomentCard key={moment.id} moment={moment}
          opened={opened[moment.id] || false} loadState={loadState}
          index={index} numCards={array.length}
          revealConRef={revealConRef} revealCallback={openMoment}
        />
      ))}
    </div>
  )
}

function OpenPackPrompt({ pack, loadState, openPack }: { pack: Pack, loadState: PageLoadState, openPack: () => void }) {
  const [show, setShow] = useState(false);
  const [opening, setOpening] = useState(false);
  const [videoReady, setVideoReady] = useState(false);
  const [videoEnded, setVideoEnded] = useState(false);
  const [animationEnded, setAnimationEnded] = useState(false);
  const videoRef = useRef<HTMLVideoElement>();

  const videoSrc = pack.rarity === Rarity.Epic ? packOpenEpic :
    pack.rarity === Rarity.Rare ? packOpenRare :
      packOpenCommon;

  useEffect(() => {
    setTimeout(() => {
      setShow(true);
    }, 500);
  }, []);

  useEffect(() => {
    if (loadState !== PageLoadState.OpeningMoments) { return; }

    setOpening(true);
    // let animation untilt the pack first before playing the video
    setTimeout(() => videoRef.current.play(), 250);
  }, [loadState]);

  function onVideoLoaded() {
    setVideoReady(true);
  }

  async function openClicked() {
    openPack();
  }

  function onVideoEnd() {
    setVideoEnded(true);
  }

  function onAnimationEnd() {
    setAnimationEnded(true);
  }

  const loading = loadState === PageLoadState.OpeningPack || loadState === PageLoadState.LoadingMoments;

  if (videoEnded && animationEnded) { return null; }
  return (
    <div className="open-pack-prompt-container reveal-child d-flex flex-wrap justify-content-center">
      <div className={`open-pack-prompt m-3 ${show ? 'show' : ''}`}>
        <video className={`open-pack-vid ${opening ? 'opening': ''}`} preload="auto"
          onCanPlayThrough={onVideoLoaded} onEnded={onVideoEnd} onAnimationEnd={onAnimationEnd}
          ref={videoRef}
        >
          <source src={videoSrc} type="video/webm" />
        </video>
        <div className="open-pack-btn-con d-flex justify-content-center align-items-center">
          { loadState === PageLoadState.ErrorOpeningPack ? (
            <Alert.Danger>Error opening pack.</Alert.Danger>
          ) : loadState === PageLoadState.ErrorLoadingMoments ? (
            <Alert.Danger>Error loading moments.</Alert.Danger>
          ) : loadState === PageLoadState.WaitingToOpenPack ? (
            <button className={`btn ${(opening || !videoReady) ? 'visually-hidden' : ''}`}
              onClick={openClicked}
              disabled={opening || !videoReady}
            >Open pack?</button>
          ) : loading ? (
            <div className="spinner-border text-light" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
}

function FinishedPrompt(): JSX.Element {
  const navigate = useNavigate();

  function backToCollection() {
    navigate('/profile/me/packs');
  }

  return (
    <div className="reveal-finished-prompt reveal-child">
      <p>Click the moments to see more details, or go back to collection.</p>
      <div>
        <button className="btn" onClick={backToCollection}>Back to Collection</button>
      </div>
    </div>
  );
}

class _RevealPackPage extends React.Component<PageProps, PageState> {
  revealConRef = createRef<HTMLDivElement>();
  fadeShowTimeout: NodeJS.Timeout = undefined;

  constructor(props) {
    super(props);

    this.state = {
      packId: undefined,
      pack: undefined,
      loadState: PageLoadState.Idle,
      moments: undefined,
      opened: {},
      fadeShow: false,
      navigateTo: undefined
    };

    this.openPack = this.openPack.bind(this);
    this.onOpenedAll = this.onOpenedAll.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  componentDidMount() {
    this.load();
  }

  componentDidUpdate(prevProps: Readonly<PageProps>, prevState: Readonly<PageState>, snapshot?: any): void {
    if (prevState.loadState !== this.state.loadState) {
      if (this.isLoadState(PageLoadState.WaitingToOpenPack)) {
        this.fadeShowTimeout = setTimeout(() => this.setState({ fadeShow: true }), cssDelay);
      }
    }
  }

  componentWillUnmount() {
    if (this.fadeShowTimeout !== undefined) {
      clearTimeout(this.fadeShowTimeout);
    }
  }

  private async load() {
    // parse id
    this.setState({ loadState: PageLoadState.ParsingId });
    let packId: number;
    try {
      packId = parseInt(this.props.packIdStr);
    } catch (e) {
      log('error occurred while parsing ID:', e);
      this.setState({ loadState: PageLoadState.ErrorParsingId });
      return;
    }

    // load pack
    this.setState({ loadState: PageLoadState.LoadingPack, packId });
    let pack: Pack;
    try {
      pack = await this.props.packsService.getSinglePack(packId);
    } catch (e) {
      log('error occurred while loading pack:', e);
      this.setState({ loadState: PageLoadState.ErrorLoadingPack });
      return;
    }

    // is it already open
    if (pack.opened) {
      log('error occurred while loading pack:', new Error(`Pack ${pack.id} already opened`));
      this.setState({ loadState: PageLoadState.ErrorPackAlreadyOpened });
      return;
    }

    // prompt user
    this.setState({ loadState: PageLoadState.WaitingToOpenPack, pack });
  }

  private isLoadState(loadState: PageLoadState): boolean {
    return this.state.loadState === loadState;
  }

  private async openPack() {
    // open pack
    this.setState({ loadState: PageLoadState.OpeningPack });
    let momentIds: number[];
    try {
      momentIds = await this.props.packsService.openPack(this.state.packId);
    } catch (e) {
      log('error occurred while opening pack:', e);
      this.setState({ loadState: PageLoadState.ErrorOpeningPack });
      return;
    }
    if (!momentIds || momentIds.length === 0) {
      log('error occurred while opening pack: no moments acquired');
      this.setState({ loadState: PageLoadState.ErrorOpeningPack });
      return;
    }

    // load moments
    this.setState({ loadState: PageLoadState.LoadingMoments });
    let momentMap: Play.NFTMetadataMap;
    try {
      momentMap = await this.props.metadataService.loadNFTMetadata(
        momentIds, this.props.userState.address
      );
    } catch (e) {
      log('error occurred while loading metadata:', e);
      this.setState({ loadState: PageLoadState.ErrorLoadingMoments });
      return;
    }

    const moments = Object.values(momentMap);
    this.setState({ loadState: PageLoadState.OpeningMoments, moments });
  }

  private closeModal() {
    this.setState({ navigateTo: '/profile/me/packs' });
  }

  private onOpenedAll() {
    this.setState({ loadState: PageLoadState.Finished });
    log('onOpenedAll called');
  }

  private getStatus(): JSX.Element {
    switch (this.state.loadState) {
    case PageLoadState.Idle: return <Alert.Info><AlertSpinner /> Starting...</Alert.Info>;
    case PageLoadState.ParsingId: return <Alert.Info><AlertSpinner /> Loading...</Alert.Info>;
    case PageLoadState.ErrorParsingId: return <Alert.Danger>Invalid Pack ID format.</Alert.Danger>;
    case PageLoadState.LoadingPack: return <Alert.Info><AlertSpinner /> Loading pack...</Alert.Info>;
    case PageLoadState.ErrorLoadingPack: return <Alert.Danger>Error occurred while loading pack.</Alert.Danger>;
    case PageLoadState.ErrorPackAlreadyOpened: return <Alert.Danger>Pack already opened.</Alert.Danger>;
    case PageLoadState.WaitingToOpenPack: return null;
    case PageLoadState.OpeningPack: return null;
    case PageLoadState.ErrorOpeningPack: return <Alert.Danger>Error occurred while opening pack.</Alert.Danger>;
    case PageLoadState.LoadingMoments: return null;
    case PageLoadState.ErrorLoadingMoments: return <Alert.Danger>Error occurred while loading moments.</Alert.Danger>;
    case PageLoadState.OpeningMoments: return null;
    case PageLoadState.Finished: return null;
    default: return <Alert.Danger>Unknown page state.</Alert.Danger>;
    }
  }

  private isReady(): boolean {
    switch (this.state.loadState) {
    case PageLoadState.WaitingToOpenPack: // intentional fallthroughs
    case PageLoadState.OpeningPack:
    case PageLoadState.ErrorOpeningPack:
    case PageLoadState.LoadingMoments:
    case PageLoadState.ErrorLoadingMoments:
    case PageLoadState.OpeningMoments:
    case PageLoadState.Finished: return true;
    default: return false;
    }
  }

  render() {
    return (
      <Page title="Reveal" className="reveal-page container py-2">
        {this.state.navigateTo && (
          <Navigate to={this.state.navigateTo} />
        )}
        {this.getStatus()}
        {this.isReady() && (
          <ModalPortal>
            <div className={`reveal-con ${this.state.fadeShow ? 'fade-show' : ''}`} ref={this.revealConRef}>
              <ModalCloseButton onClick={this.closeModal} />
              <div className="reveal-wrapper">
                {this.state.moments && <RevealSection moments={this.state.moments} loadState={this.state.loadState} revealConRef={this.revealConRef} onOpenedAll={this.onOpenedAll} />}
                {this.state.pack && <OpenPackPrompt pack={this.state.pack} loadState={this.state.loadState} openPack={this.openPack} />}
                {this.state.loadState === PageLoadState.Finished && <FinishedPrompt />}
              </div>
            </div>
          </ModalPortal>
        )}
      </Page>
    );
  }
}

export function RevealPackPage() {
  const { packId } = useParams();
  const userState = useUserState();
  const packsService = getPacksService();
  const metadataService = getMetadataService();

  return (
    <_RevealPackPage
      packIdStr={packId}
      packsService={packsService}
      metadataService={metadataService}
      userState={userState}
    />
  )
}
