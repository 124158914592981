import React from 'react';
import debug from 'debug';

import { Navigate, useParams } from 'react-router';
import { getMarketplace, getMetadataService } from '../../services/ServiceFactory';
import { UserAccount } from '../../common/UserAccount';
import { useUserState } from '../../services/user/UserContext';
import { UserService } from '../../services/user/UserService';
import { useUserService } from '../../services/user/UserServiceContext';
import { Page } from '../Page';
import { Alert } from '../../components/alerts/Alert';

import './MySingleMomentPage.scss';

import { MetadataService } from '../../services/metadata/MetadataService';
import { NFTCardList } from '../../components/nft-card/NFTCardList';
import { NFTCardSize } from '../../components/nft-card/NFTCard';
import { Moment } from '../../components/moment/Moment';
import { SellDialog } from './SellDialog';

import sellIcon from '../../assets/svg/marketplace.svg';

const log = debug('app:pages:profile:MySingleMomentPage');

type NFTPageProps = {
  userState: UserAccount,
  userService: UserService,
  metadataService: MetadataService,

  nftIdStr: string,
  profileId: string
}

type NFTPageState = {
  nftId: number,
  metadata: Play.NFTMetadata,
  loading: boolean,
  sellDialog: boolean,
  addingToMarket: boolean,
  error: string,
  redirectTo: string,
  price: string,
  priceError: string,
  fee: string,

  otherNftMetadata: Play.NFTMetadata[],
  loadingOtherNfts: boolean
}

const priceRegex = /^\d+\.\d+$/;

class InternalNFTPage extends React.Component<NFTPageProps, NFTPageState> {
  private marketplace = getMarketplace();
  constructor(props: NFTPageProps) {
    super(props);

    this.state = {
      nftId: undefined,
      metadata: undefined,
      loading: false,
      sellDialog: false,
      addingToMarket: false,
      error: undefined,
      redirectTo: undefined,
      price: '',
      priceError: undefined,
      fee: '5',

      otherNftMetadata: [],
      loadingOtherNfts: false,
    };

    this.addToMarket = this.addToMarket.bind(this);
    this.onPriceChange = this.onPriceChange.bind(this);
    this.openSellDialog = this.openSellDialog.bind(this);
    this.closeSellDialog = this.closeSellDialog.bind(this);
  }

  componentDidMount() {
    const nftId = this.parseId();
    if (nftId !== undefined) {
      this.loadMetadata(nftId);
    }
    this.loadMultiple({ skip: nftId });
  }

  // TODO allow changing of ID

  private parseId(): number {
    if (!this.props.nftIdStr) {
      this.setState({ error: 'Missing ID.' });
      return;
    }
    try {
      const nftId = parseInt(this.props.nftIdStr);
      if (nftId !== this.state.nftId) {
        this.setState({ error: undefined, nftId });
      }
      return nftId;
    } catch (e) {
      log('error parsing ID', e);
      this.setState({ error: 'Invalid ID format.' });
      return undefined;
    }
  }

  private async loadMetadata(id?: number) {
    if (id === undefined) {
      id = this.state.nftId;
    }
    if (id === undefined) {
      return;
    }
    this.setState({ loading: true });

    try {
      const metadata = await this.props.userService.loadSingleMetadata(id);
      this.setState({ loading: false, metadata });
    } catch (e) {
      this.setState({ loading: false, error: e.message})
    }
  }

  private async loadMultiple(opts: { skip: number | undefined }) {
    this.setState({ loadingOtherNfts: true });

    try {
      const profileId = this.props.profileId === 'me' ?
        this.props.userState.address
        : this.props.profileId;
      const account = await this.props.userService.loadPublicAccount(profileId);
      const nftsToLoad = account?.nfts?.filter(x => x !== opts.skip);
      if (!nftsToLoad.length) {
        this.setState({ otherNftMetadata: [] });
        return;
      }
      const metadataMap = await this.props.metadataService.loadNFTMetadata(nftsToLoad, profileId);
      const otherNftMetadata = Object.values(metadataMap).filter(metadata => !!metadata);
      this.setState({ otherNftMetadata });
    }
    finally {
      this.setState({ loadingOtherNfts: false });
    }
  }

  private async addToMarket() {
    if (!priceRegex.test(this.state.price)) { return; }
    this.setState({ addingToMarket: true });
    try {
      await this.marketplace.addToMarket(this.state.nftId, this.state.price);
      this.setState({
        addingToMarket: false,
        redirectTo: '/profile',
        error: undefined
      });
    } catch (e) {
      this.setState({
        addingToMarket: false,
        error: e.message
      });
    }
  }

  private onPriceChange(e) {
    const price = e.target.value;
    const priceError = priceRegex.test(price) ? undefined : 'Invalid price format';
    this.setState({ price, priceError });
  }

  private openSellDialog() {
    this.setState({ ...this.state, sellDialog: true});
  }

  private closeSellDialog() {
    this.setState({ ...this.state, sellDialog: false});
  }

  render() {
    const addingToMarket = this.state.addingToMarket;
    return (
      <Page className="my-single-moment-page container align-items-stretch py-2">
        {this.state.redirectTo && (
          <Navigate to={this.state.redirectTo} />
        )}
        {this.state.error && (
          <Alert.Danger>{this.state.error}</Alert.Danger>
        )}
        {this.state.loading ? (
          <Alert.Info>Loading...</Alert.Info>
        ) : this.state.metadata ? (
          <>
            { false && <>
              <div className="mb-3">
                <label htmlFor="nft-price" className="form-label">Price</label>
                <input id="nft-price" className="form-control" value={this.state.price} onChange={this.onPriceChange} />
                {this.state.priceError && (
                  <span>{this.state.priceError}</span>
                )}
              </div>
              <div className="mb-3">
                <button className="btn btn-success" disabled={
                  addingToMarket || !!this.state.priceError ||
                  !this.state.metadata || !!this.state.metadata.reservedFor
                } onClick={this.addToMarket}>
                  Put up for sale
                </button>
                {addingToMarket && (
                  <span>Adding to market...</span>
                )}
              </div>
            </>
            }

            <Moment metadata={this.state.metadata} backText="Back to Collections" backURL={ `/profile/${this.props.profileId}/moments` } >
              <div>
                <button className="btn btn-primary" onClick={ this.openSellDialog }>
                  <img src={sellIcon} />
                  PLACE FOR SALE
                </button>
              </div>
            </Moment>

          </>
        ) : (
          <Alert.Info>No metadata</Alert.Info>
        )}
        {this.state.loadingOtherNfts ? (
          <div className="alert alert-info">Loading more NFTs...</div>
        ) : (
          <NFTCardList nftItems={this.state.otherNftMetadata} cardSize={NFTCardSize.Small} baseUrl={`/profile/${this.props.profileId}/moments/`} />
        )}
        {this.state.sellDialog && this.state.metadata &&
          <SellDialog key="selldialog" onClose={ this.closeSellDialog } metadata={this.state.metadata} onPriceChange={ this.onPriceChange }
            price={ this.state.price } addToMarket={ this.addToMarket } error={ this.state.priceError ? 'Invalid price format' : '' }
            fee={ this.state.fee } addingToMarket={ this.state.addingToMarket }/>}
      </Page>
    );
  }
}

export function MySingleMomentPage() {
  const userState = useUserState();
  const userService = useUserService();
  const metadataService = getMetadataService();

  const { profileId, nftId } = useParams();

  return (
    <InternalNFTPage
      userState={userState}
      userService={userService}
      metadataService={metadataService}
      nftIdStr={nftId}
      profileId={profileId}
    />
  )
}
