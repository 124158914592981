import React, { MouseEvent } from 'react';
import debug from 'debug';

import { Navigate, useParams } from 'react-router';
import { getMarketplace } from '../../services/ServiceFactory';
import { Page } from '../Page';
import { Alert } from '../../components/alerts/Alert';

import { TransferModal } from '../../components/transfer-modal/TransferModal';
import { UserAccount } from '../../common/UserAccount';
import { useUserState } from '../../services/user/UserContext';
import { SaleState } from '../../common/SaleState';
import { Moment } from '../../components/moment/Moment';
import { useUserService } from '../../services/user/UserServiceContext';

import './MarketplaceSinglePage.scss';
import warnIcon from '../../assets/svg/warn.svg';
import ethIcon from '../../assets/svg/eth-price.svg';

const log = debug('app:pages:marketplace:MarketplaceSinglePage');

type StoreSinglePageProps = {
  nftIdStr: string,
  userState: UserAccount
};

type StoreSinglePageState = {
  nftId: number,
  nftMetadata: Play.NFTMetadata,
  pageLoading: boolean,
  buying: boolean,
  saleState: SaleState,
  redirectTo: string,
  showDepositModal: boolean,
  error: string
};

function SaleStateStatus(
  { saleState, openDepositModal }: {
    saleState: SaleState,
    openDepositModal: (e?: React.MouseEvent<any>) => void
  }
): JSX.Element {
  const userService = useUserService();

  function login(e: MouseEvent<HTMLAnchorElement>) {
    e.preventDefault();
    userService.logIn();
  }

  function link(e: MouseEvent<HTMLAnchorElement>) {
    e.preventDefault();
    userService.link();
  }
  switch (saleState) {
  case SaleState.LogInRequired:
    return (
      <>
        <img src={warnIcon} />
        <div>You must connect to <a href="/login" onClick={login}>Sway Life</a> with your wallet to buy</div>
      </>
    );
  case SaleState.LinkRequired:
    return (
      <>
        <img src={warnIcon} />
        <div>You must link to <a href="/account" onClick={link}>Sway Life</a> with your wallet to buy</div>
      </>
    );
  case SaleState.OutOfStock:
    return (
      <>
        <img src={warnIcon} />
        <div>Out of stock.</div>
      </>
    );
  case SaleState.Unaffordable:
    return (
      <>
        <img src={warnIcon} />
        <div>Not enough balance buy. <a href="#" onClick={openDepositModal}>Deposit ETH</a> to be able to buy.</div>
      </>
    );
  case SaleState.Available:
    return null;
  default:
    return (
      <>
        <img src={warnIcon} />
        <div>Unknown error occurred</div>
      </>
    );
  }
}

class InternalMarketplaceSinglePage extends React.Component<StoreSinglePageProps, StoreSinglePageState> {
  private marketplace = getMarketplace();
  constructor(props) {
    super(props);

    this.state = {
      nftId: undefined,
      nftMetadata: undefined,
      pageLoading: true,
      buying: false,
      saleState: undefined,
      redirectTo: undefined,
      showDepositModal: false,
      error: null
    };

    this.buy = this.buy.bind(this);
    this.openDepositModal = this.openDepositModal.bind(this);
    this.closeDepositModal = this.closeDepositModal.bind(this);
  }

  componentDidMount() {
    this.init();
  }

  componentDidUpdate(prevProps: Readonly<StoreSinglePageProps>, prevState: Readonly<StoreSinglePageState>) {
    if (prevProps.userState.balance !== this.props.userState.balance ||
      prevState.nftId !== this.state.nftId ||
      prevProps.userState.linked !== this.props.userState.linked ||
      prevProps.userState.loggedIn !== this.props.userState.loggedIn
    ) {
      log('updating sale state');
      this.updateSaleState();
    }
  }

  private async init() {
    let nftId: number = undefined;
    try {
      nftId = parseInt(this.props.nftIdStr);
      this.setState({ nftId });
    } catch (e) {
      // render will take care of showing the right error for this
      this.setState({ pageLoading: false });
    }

    if (nftId === undefined) { return; }

    try {
      await this.load(nftId);
    } catch (e) {
      this.setState({ error: e.message });
    }
    this.setState({ pageLoading: false });
  }

  private async load(nftId: number) {
    try {
      const nftMetadata = await this.marketplace.loadSingleSaleMetadata(nftId);
      this.setState({ nftMetadata });
    } catch (e) {
      log(e);
      throw new Error('Error occurred while loading metadata');
    }
  }

  private async updateSaleState() {
    if (!this.state.nftId) { return; }
    if (this.state.buying) { return; }

    const saleState = await this.marketplace.canPurchase(this.state.nftId);
    this.setState({ saleState });
  }

  private async buy() {
    if (!this.state.nftId) { return; }

    this.setState({ buying: true });
    try {
      await this.marketplace.purchase(this.state.nftId);
      this.setState({ redirectTo: '/profile' });
    } catch (e) {
      log(e);
      this.setState({ error: e.message });
    }
    this.setState({ buying: false });
  }

  private openDepositModal(e?: React.MouseEvent<any>) {
    if (e) {
      e.preventDefault();
    }

    this.setState({ showDepositModal: true });
  }

  private closeDepositModal() {
    this.setState({ showDepositModal: false });
  }

  render() {
    return (
      <Page className="store-single-page container py-2">
        {this.state.redirectTo && (
          <Navigate to={this.state.redirectTo} />
        )}
        {this.state.pageLoading ? (
          <Alert.Info>
            Loading...
          </Alert.Info>
        ) : this.state.nftId === undefined ? (
          <Alert.Danger>
            Invalid NFT ID format.
          </Alert.Danger>
        ) : !this.state.nftMetadata ? (
          <Alert.Warning>
            No metadata.
          </Alert.Warning>
        ) : (
          <Moment metadata={this.state.nftMetadata} backText="Back to Marketplace" backURL="/marketplace">
            <div className="marketplace-single-moment-price d-flex flex-row justify-content-start">
              <img src={ethIcon} />
              <div className="price-value">{ this.state.nftMetadata.price }</div>
            </div>
            <div className="marketplace-single-moment-royalty">
              Includes 6.99% royalty
            </div>
            <div>
              <button className="btn btn-primary marketplace-single-moment-buy"
                onClick={this.buy}
                disabled={this.state.buying || this.state.saleState !== SaleState.Available}
              >
                BUY NOW
              </button>
            </div>
            <div className="marketplace-single-moment-connect d-flex justify-content-center">
              <SaleStateStatus
                saleState={this.state.saleState}
                openDepositModal={this.openDepositModal}
              />
            </div>
            {this.state.error && (
              <div className="marketplace-single-moment-error d-flex justify-content-center">
                <img src={warnIcon} />
                <div>{this.state.error}</div>
              </div>
            )}
          </Moment>
        )}
        <TransferModal
          show={this.state.showDepositModal}
          minimum={this.state.nftMetadata?.price}
          deposit
          onDismiss={this.closeDepositModal}
        />
      </Page>
    );
  }
}

export function MarketplaceSinglePage() {
  const userState = useUserState();
  const { nftId } = useParams();

  return (
    <InternalMarketplaceSinglePage
      userState={userState}
      nftIdStr={nftId}
    />
  );
}
