import React, { Component, FunctionComponent } from 'react';
import ReactDOM from 'react-dom';

import './MockModal.scss';

export type ModalCallback = (...props: any[]) => any;
export type ModalCloseCallback = () => void;
export type ModalWrapperProps = {
  title: string,
  className?: string,
  children: JSX.Element | JSX.Element[],
  close: () => void
};
export type ContentComponentProps = {
  callback?: ModalCallback,
  close?: () => void,
  [prop: string]: any
};

export function MockModalWrapper({
  title, className, children, close
}: ModalWrapperProps) {
  return (
    <div className={`mock-modal card ${className}`}>
      <div className="card-header">
        {title}
        <button type="button" className="btn-close" aria-label="Close" onClick={close}></button>
      </div>
      {children}
      <div className="card-footer">
        <small className="text-muted">Mock data mode</small>
      </div>
    </div>
  );
}

export class MockModal {
  private element: HTMLDivElement;
  constructor(
    private title: string,
    private component: React.ComponentClass<ContentComponentProps> | React.FunctionComponent<ContentComponentProps>,
    private props?: {[prop: string]: any},
    private modalClassName?: string,
    private callback?: ModalCallback,
    private closeCallback?: ModalCloseCallback
  ) {
    this.close = this.close.bind(this);
    this.init();
  }

  private init() {
    const div = document.createElement('div');
    div.classList.add('mock-modal-backdrop');
    div.addEventListener('click', e => {
      if (e.target === e.currentTarget) {
        this.close();
      }
    });

    const content = React.createElement(this.component, {
      ...this.props,
      callback: this.callback,
      close: this.close
    });

    ReactDOM.render(
      <React.StrictMode>
        <MockModalWrapper title={this.title} close={this.close} className={this.modalClassName}>
          {content}
        </MockModalWrapper>
      </React.StrictMode>,
      div
    );

    this.element = div;
    document.body.appendChild(div);
  }

  destroy() {
    if (!this.element) { return false; }

    ReactDOM.unmountComponentAtNode(this.element);
    document.body.removeChild(this.element);
    this.element = null;
    return true;
  }

  private close() {
    const destroyed = this.destroy();
    if (destroyed && this.closeCallback) {
      this.closeCallback();
    }
  }
}
