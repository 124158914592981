import React from 'react';
import debug from 'debug';
import { Decimal } from 'decimal.js';
import { UserAccount } from '../../common/UserAccount';
import { Action } from '../../context/GenerateContext';
import { MockStorageService } from '../mock/MockStorageService';
import { UserService } from '../user/UserService';
import { Marketplace } from './Marketplace';
import { mockensPlaces } from '../../consts';
import { SaleState } from '../../common/SaleState';
import { waitForTx } from '../mock/MockTxScreen';
import { migrateMetadata } from '../mock/MockMigrate';

const log = debug('app:services:marketplace:MockMarketplace');

type MarketplaceData = {
  sales: Play.NFTMetadata[]
}

const marketplaceDataKey = 'marketplaceData';

/**
 * We're assuming the service account has already been linked.
 */
export class MockMarketplace implements Marketplace {

  private data: MarketplaceData;
  private userState: UserAccount;
  private userDispatch: React.Dispatch<Action>;

  constructor(
    private mockStorageService: MockStorageService,
    private userService: UserService
  ) {
    try {
      this.data = mockStorageService.getJson(marketplaceDataKey) ?? { sales: [] };
    } catch (e) {
      this.data = { sales: [] };
      mockStorageService.putJson(marketplaceDataKey, this.data);
    }
    this.migrate();
  }

  private migrate() {
    this.data.sales.forEach(migrateMetadata);
    this.mockStorageService.putJson(marketplaceDataKey, this.data);
  }

  private async linkCheck(): Promise<void> {
    if (!this.userState.loggedIn) {
      throw new Error('Cannot purchase: not logged in');
    }
    const linked = await this.userService.checkIfLinked(true);
    if (!linked) {
      throw new Error('Cannot purchase: account not linked');
    }
  }

  setUserState(state: UserAccount): void {
    this.userState = state;
  }
  setUserDispatch(dispatch: React.Dispatch<Action>): void {
    this.userDispatch = dispatch;
  }
  async loadSales(): Promise<Play.NFTMetadata[]> {
    return [...this.data.sales];
  }
  async loadSingleSaleMetadata(nftId: number): Promise<Play.NFTMetadata> {
    return this.data.sales.find(sale => sale.id === nftId);
  }
  async addToMarket(nftId: number, price: string): Promise<void> {
    await this.linkCheck();

    const nfts = [...this.userState.nfts];
    const nftIndex = nfts.findIndex(id => nftId === id);
    if (nftIndex === -1) {
      throw new Error('NFT not found');
    }

    const metadata = await this.userService.loadSingleMetadata(nftId);

    await waitForTx(`Transaction: Approve putting up "${metadata.name}" for sale for ${price}?`);

    this.data.sales.push({
      ...metadata,
      price
    });
    this.mockStorageService.putJson(marketplaceDataKey, this.data);

    nfts.splice(nftIndex, 1);
    const accountDetails = this.userService.getAccountDetails(this.userState.address);
    this.userService.putAccountDetails(this.userState.address, {
      ...accountDetails,
      nfts
    });
  }
  async purchase(nftId: number): Promise<void> {
    await this.linkCheck();

    const saleIndex = this.data.sales.findIndex(sale => sale.id === nftId);
    if (saleIndex === -1) {
      throw new Error('NFT not found or not for sale');
    }

    const sale = this.data.sales[saleIndex];
    const salePrice = new Decimal(sale.price);
    const balance = new Decimal(this.userState.balance);
    
    if (balance.lessThan(salePrice)) {
      throw new Error('Cannot afford');
    }

    await waitForTx(`Transaction: Approve purchasing "${sale.name}"?`);

    const newBalance = balance.sub(salePrice);
    this.data.sales.splice(saleIndex, 1);

    const nfts = [...this.userState.nfts, sale.id as number];

    const accountDetails = this.userService.getAccountDetails(this.userState.address);
    this.userService.putAccountDetails(this.userState.address, {
      ...accountDetails,
      balance: newBalance.toFixed(mockensPlaces),
      nfts
    });
    this.mockStorageService.putJson(marketplaceDataKey, this.data);
  }
  async canPurchase(nftId: number): Promise<SaleState> {
    if (!this.userState.loggedIn) {
      return SaleState.LogInRequired;
    }
    const linked = await this.userService.checkIfLinked(true);
    if (!linked) {
      return SaleState.LinkRequired;
    }

    const saleIndex = this.data.sales.findIndex(sale => sale.id === nftId);
    if (saleIndex === -1) {
      throw new Error('NFT not found or not for sale');
    }

    const sale = this.data.sales[saleIndex];
    const salePrice = new Decimal(sale.price);
    const balance = new Decimal(this.userState.balance);

    if (balance.lt(salePrice)) {
      return SaleState.Unaffordable;
    }

    return SaleState.Available;
  }
}
