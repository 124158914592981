import { Link } from 'react-router-dom';
import './NFTCard.scss';

import mockCommon from '../../assets/sample-moments/mockCommon.png';
import mockRare from '../../assets/sample-moments/mockRare.png';
import mockEpic from '../../assets/sample-moments/mockEpic.png';

export enum NFTCardSize {
  Small = 'sm',
  Medium = 'md',
  Large = 'lg'
}

export type NFTCardProps = {
  nftMetadata: Play.NFTMetadata,
  baseUrl?: string,
  size?: NFTCardSize,
  showPrice?: boolean
};

export function NFTCard({
  nftMetadata, baseUrl = undefined, size = NFTCardSize.Small,
  showPrice = false
}: NFTCardProps) {
  const classNames = ['card', 'nft-card'];
  classNames.push(`nft-card-${size}`);
  const rarity = nftMetadata.rarity.toLowerCase();
  classNames.push(rarity);

  const showContent = size === NFTCardSize.Medium || size === NFTCardSize.Large;

  const videoSrc = (showContent && nftMetadata.uri) ? `https://ipfs.io/ipfs/${nftMetadata.uri.split('://')[1]}` : null;
  const imgSrc = !showContent ? (nftMetadata.card ?? (rarity === 'common' ? mockCommon :
    rarity === 'rare' ? mockRare :
      mockEpic)) : null;

  const description = nftMetadata.description ?? 'Dramatic - Nov 12 20210, Base Set (Sway House Season 1)';

  const children = <>
    {videoSrc && (
      <video className="card-img-top" loop controls preload="auto">
        <source src={videoSrc} type="video/mp4" />
      </video>
    )}
    <div className="d-flex flex-row align-items-start">
      <div>
        {imgSrc && (
          <img src={imgSrc} className="nft-card-preview" />
        )}
        {showPrice && nftMetadata.price && (
          <div className="d-flex flex-column align-items-center">
            <p className="nft-card-price-label"> Lowest Ask </p>
            <p className="nft-card-price-value">
              USD $ {nftMetadata.price}
            </p>
            <p className="nft-card-price-average"> $ { nftMetadata.price } Avg Sale</p>
          </div>
        ) }
      </div>
      <div className="flex-row">
        <h5 className="nft-card-title">
          { nftMetadata.name }
        </h5>
        <p className="nft-card-subtitle">#2 / 19</p>
        <p className="nft-card-text">{description}</p>
        <p className="nft-card-footer">{nftMetadata.rarity} #100100{nftMetadata.id}</p>
      </div>
    </div>
  </>

  return (baseUrl ?
    <Link to={baseUrl + nftMetadata.id} className={classNames.join(' ')}>
      { children }
    </Link> :
    <div className={classNames.join(' ')}>
      { children }
    </div>
  );
}
